import React, { Component } from 'react';
import { restCall } from '../services/rest';
import { signOut, dateLongFormat } from '../services/utils';
import { Pane, Heading, toaster, Table, Spinner } from 'evergreen-ui';

class Messages extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      id: this.props.match.params.id,
      messageMappedId: this.props.match.params.id,
      messages: [],
      isContentLoading: true
    }
  }

  componentDidMount() {
    // Call, need to without 'email' from user..
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    restCall('/get-messages/', 'POST', tokenId.reg_auth, {id: this.state.id})
      .then(res => res.json())
      .then(data => {
        if (!data.result  && data.status === 403) {
          signOut(2000); // Delay user before redirect
          return toaster.warning(data.msg);
        }
        if (!data.result) {
          setTimeout(() => this.setState({isContentLoading: false}), 300)
          return toaster.warning(data.msg);
        }
        setTimeout(() => this.setState(prevState => ({messages: data.msgArr, isContentLoading: false})), 300);
      })
      .catch(err => {
        this.setState({isLoading: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });

  }

  render() {

    return (
      <Pane 
        className="flex-grid m-pane-out"
        alignItems="top"
        justifyContent="center"
        padding={25}>
        <Pane
          className="flex-grid"
          flexBasis={850}
          alignItems="top"
          justifyContent="center"
          marginBottom={25}
          padding={10}>
          <Pane width="100%">
            <Heading 
              size={700}
              marginBottom={15}>
              Messages
            </Heading>
            {/* isContentLoading */}
            {this.state.isContentLoading && <Pane
              className="pane-el m-pane-el"
              elevation={1}
              width="100%"
              padding={50}>
                <Spinner marginX="auto"/>
            </Pane>}
            {!this.state.isContentLoading && <Pane
              className="pane-el m-pane-el"
              elevation={1}
              width="100%"
              padding={50}>

                  <Table
                    className="table-messages">
                    <Table.Head>
                      <Table.TextHeaderCell>
                        From
                      </Table.TextHeaderCell>
                      <Table.TextHeaderCell>
                        Subject
                      </Table.TextHeaderCell>
                      <Table.TextHeaderCell>
                        {window.innerWidth < 750 ? 'Rec. Date' : 'Received Date'}
                      </Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body height={410}>
                    { this.state.messages.map((val, idx) => {
                      val.messageMappedId = this.state.id;
                        return  <Table.Row key={idx} className={ val.isRead ? "" : "p-relative message-is-unread"} isSelectable onSelect={() => this.props.history.push({pathname: '/message/' + val.id, state: val})}>
                                  <Table.TextCell>
                                    <strong>{(val.type === 'startup' && val.companyname !== undefined && val.companyname !== '') ? val.companyname : val.firstname + ' ' + val.lastname}</strong>
                                  </Table.TextCell>
                                  <Table.TextCell>{val.subject}</Table.TextCell>
                                  <Table.TextCell>
                                    <small>{dateLongFormat(val.updated)}</small>
                                  </Table.TextCell>
                                </Table.Row>;
                      })
                    }
                    </Table.Body>
                  </Table>

            </Pane>}
          </Pane>
        </Pane>
      </Pane>
    )
  }

}

export default Messages;