import React, { Component } from 'react';
import { restCall } from '../services/rest';
import { signOut } from '../services/utils';
import { isFormInvalid, isGreaterThan, dateLongFormat } from '../services/utils';
import debounce from 'debounce';
import { Link } from 'react-router-dom';
import { Pane, Heading, Paragraph, Textarea, Button, toaster, IconButton, Text, Small} from 'evergreen-ui';

class Message extends Component {

  constructor(props) {
    super(props);
    this.state = this.props.location.state;
    this.state.message = [];
    this.state.messageReply = '';
    this.state.errors = {messageReply: false, subject: false};
    this.state.isInvalid = true;
    this.state.isLoading = false;
  }

  componentDidMount() {
    // Call, need to without 'email' from user..
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    restCall('/get-message/', 'POST', tokenId.reg_auth, {id: this.state.id})
      .then(res => res.json())
      .then(data => {
        if (!data.result) {
          signOut(2000); // Delay user before redirect
          return toaster.warning(data.msg);
        }

        if (!this.state.isRead)
          this.hasRead();
        this.setState({message: data.data.messages});
      })
      .catch(err => {
        this.setState({isLoading: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  hasRead() {
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    restCall('/has-read/', 'POST', tokenId.reg_auth, {id: this.state.messageMappedId, messageId: this.state.id, hasRead: true})
      .then(res => res.json())
      .then(data => {
        if (!data.result) {
          signOut(2000); // Delay user before redirect
          return toaster.warning(data.msg);
        }
        this.setState({ isRead: true});
      })
      .catch(err => {
        this.setState({isLoading: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  initMessage() {
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    const replyObj = {messageId: this.state.id, senderId: this.state.senderId, recId: this.state.recId, messagesMappedId: this.state.messageMappedId, userId: tokenId.id, messageReply: this.state.messageReply};
    let messageArr = this.state.message;
    this.setState({isLoading: true});
    restCall('/message-reply/', 'POST', tokenId.reg_auth, replyObj)
      .then(res => res.json())
      .then(data => {
        if (!data.result) {
          signOut(2000); // Delay user before redirect
          return toaster.warning(data.msg);
        }
        messageArr.push({message: replyObj.messageReply, created: new Date().toISOString(), senderId: tokenId.id });
        this.setState({messageReply: '', isInvalid: true, message: messageArr, isLoading: false});
        // toaster.success('Your messages has been sent.')
      })
      .catch(err => {
        this.setState({isLoading: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  render() {
    return (
      <Pane 
        className="flex-grid m-pane-out"
        alignItems="top"
        justifyContent="center"
        padding={25}>
        <Pane
          className="flex-grid"
          flexBasis={850}
          alignItems="top"
          justifyContent="center"
          marginBottom={25}
          padding={10}>
          <Pane width="100%">
            <Heading 
              size={700}
              marginBottom={15}>
              Message
            </Heading>
            <Pane
              className="pane-el m-pane-el"
              elevation={1}
              width="100%"
              padding={50}>
              <IconButton
                intent="none"
                icon="arrow-left"
                marginBottom={25}
                onClick={() => this.props.history.goBack()}/>
              <Pane
                height={1}
                marginBottom={25}
                background="#EDF0F2"/>
              
              { 
                this.state.message.map((val, idx) => {
                  return  <Pane key={idx}>
                            <Pane
                              display="flex"
                              marginBottom={5}>
                              {this.state.senderId === val.senderId ?
                                <Link to={'/list/developer/' + this.state.senderId}>
                                  <Heading
                                    size={300}>
                                    {(this.state.type === 'startup' && this.state.companyname !== undefined && this.state.companyname !== '') ? this.state.companyname : this.state.firstname + ' ' + this.state.lastname }
                                  </Heading>
                                </Link>
                                :
                                <Heading
                                size={300}>
                                  {'You'}
                                </Heading>
                              }
                              
                              <Text
                                size={300}
                                marginLeft="auto">
                                <Small>{ dateLongFormat(val.created) }</Small>
                              </Text>
                            </Pane>
                            <Pane 
                              background="tint1"
                              padding={10}
                              marginBottom={35}
                              borderRadius={3}>
                              <Paragraph 
                                size={400}>
                                { val.message } 
                              </Paragraph>
                            </Pane>
                          </Pane>;
                })
              }
              
              <Pane>
                <Heading size={200} marginBottom={5}>
                  Reply to message:
                </Heading>
                <Textarea
                  id="textarea-1"
                  height={175}
                  marginBottom={10}
                  spellCheck={true}
                  grammarly={true}
                  name="messageReply"
                  value={this.state.messageReply}
                  onChange={e => this.setState({messageReply: e.target.value})}
                  onBlur={() => {
                            this.setState({errors: { ...this.state.errors, messageReply: isGreaterThan(this.state.messageReply) }})
                            this.setState({ isInvalid: isFormInvalid({messageReply: this.state.messageReply, errors: this.state.errors})})
                          }}
                  onKeyUp={ debounce(() => {
                            this.setState({errors: { ...this.state.errors, messageReply: isGreaterThan(this.state.messageReply) }})
                            this.setState({ isInvalid: isFormInvalid({messageReply: this.state.messageReply, errors: this.state.errors})})
                          }, 100)}
                  required/>
                <Button
                  appearance="primary"
                  disabled={this.state.isInvalid}
                  isLoading={this.state.isLoading}
                  onClick={() => this.initMessage()}>
                    Send
                </Button>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    )
  }
}

export default Message;
