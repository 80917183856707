export const validateEmail = (val) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isValid = (re.test(String(val).toLowerCase()));
  return !isValid;
};

export const regValidator = props => {
  if (props.state[props[Object.keys(props)[0]]]) {
  }
};

export const validatePassword = val => {
  // REGEX: should contain at least one digit, at least one lower case, at least one upper case, at least 8 from the mentioned characters
  // const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*])[0-9a-zA-Z`~!@#$%^&*]{8,}$/; // Special char
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[0-9a-zA-Z][\w~@#$%^&*+=`|\\{}:;!.?"()[\]-]{7,}$/; // Non special char
  return !re.test(val);
};

// Must feed it correct values from source..
export const isFormInvalid = props => {
  let isInvalid = false;
  Object.keys(props.errors).forEach(val => {
    if (props.errors[val]) isInvalid = true;
  });
  // Make sure of no empty strings
  Object.keys(props).forEach(val => {
    if (props[val] === '') isInvalid = true;
  });
  return isInvalid; 
};

export const isGreaterThan = val => val.length < 2 ;

// For now.. must be linkedin profile format, as well min length of 2 charachters
export const isUrlValid = (url, inOrCompany, isError) => {
  if (url === '')
    return isError;
  const EXP_IN_OR_COMPANY = (inOrCompany === 'developer') ? /^https:\/\/www\.linkedin\.com\/in\/$/ : /^https:\/\/www\.linkedin\.com\/company\/$/;
  const isStringValid = url.split('/')[4] ? /^.{2,}$/.test(url.split('/')[4]): url.split('/')[4];
  const splitArr = url.split('/').splice(0,4).join('/') + '/';
  return !((isStringValid && isStringValid !== undefined) && (splitArr.match(EXP_IN_OR_COMPANY) && splitArr.match(EXP_IN_OR_COMPANY) !== null));
};

export const signOut = delay => {
  let ls = JSON.parse(window.localStorage.getItem('start_dev'));
  ls = {reg_auth: '', id: ''};
  window.localStorage.setItem('start_dev', JSON.stringify(ls));
  setTimeout(() => window.location.pathname = '/', delay); // delay for user to read message
};

export const dateLongFormat = date => new Date(date).toLocaleDateString('en-US', {  hour: 'numeric', minute: 'numeric', weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });

export const loggedIn = () => {
  const ls = JSON.parse(localStorage.getItem('start_dev'));
  if (ls && ls.id !== '')
    return {isLoggedIn: true, ls};
  return {isLoggedIn: false};
};

export const states = {
                        "AL": "Alabama",
                        "AK": "Alaska",
                        "AZ": "Arizona",
                        "AR": "Arkansas",
                        "CA": "California",
                        "CO": "Colorado",
                        "CT": "Connecticut",
                        "DE": "Delaware",
                        "DC": "District Of Columbia",
                        "FL": "Florida",
                        "GA": "Georgia",
                        "GU": "Guam",
                        "HI": "Hawaii",
                        "ID": "Idaho",
                        "IL": "Illinois",
                        "IN": "Indiana",
                        "IA": "Iowa",
                        "KS": "Kansas",
                        "KY": "Kentucky",
                        "LA": "Louisiana",
                        "ME": "Maine",
                        "MD": "Maryland",
                        "MA": "Massachusetts",
                        "MI": "Michigan",
                        "MN": "Minnesota",
                        "MS": "Mississippi",
                        "MO": "Missouri",
                        "MT": "Montana",
                        "NE": "Nebraska",
                        "NV": "Nevada",
                        "NH": "New Hampshire",
                        "NJ": "New Jersey",
                        "NM": "New Mexico",
                        "NY": "New York",
                        "NC": "North Carolina",
                        "ND": "North Dakota",
                        "OH": "Ohio",
                        "OK": "Oklahoma",
                        "OR": "Oregon",
                        "PA": "Pennsylvania",
                        "PR": "Puerto Rico",
                        "RI": "Rhode Island",
                        "SC": "South Carolina",
                        "SD": "South Dakota",
                        "TN": "Tennessee",
                        "TX": "Texas",
                        "UT": "Utah",
                        "VT": "Vermont",
                        "VI": "Virgin Islands",
                        "VA": "Virginia",
                        "WA": "Washington",
                        "WV": "West Virginia",
                        "WI": "Wisconsin",
                        "WY": "Wyoming"
                      };
