import React, { Component } from 'react';
import { restCall } from '../services/rest';
import { signOut, states } from '../services/utils';
import { Pane, Heading, toaster, Button, Paragraph, Dialog, TextInputField, Textarea, Label, IconButton, Link, Badge, Spinner } from 'evergreen-ui';
import { Badges } from './Badges';
import { isFormInvalid, isGreaterThan } from '../services/utils';
import debounce from 'debounce';

class Details extends Component {

  constructor(props) {
    super(props);
    
    this.isSubjectDirty = false;
    this.isMessageDirty = false;

    this.state = {
      subject: '',
      message: '',
      id: props.match.params.id,
      errors: { message: false, subject: false},
      isShown: false,
      isLoading: false,
      isContentLoading: true,
      isInvalid: true,
      firstname: '',
      lastname: '',
      city: '',
      state: '',
      languageLabels: [],
      description: '',
      linkedin: ''
    }
  }

  componentDidMount() {
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    restCall('/user/', 'POST', tokenId.reg_auth, {id: this.state.id, rest: 'get', details: true})
      .then(res => res.json())
      .then(data => {
        if (!data.result) {
          signOut(2000); // Delay user before redirect
          return toaster.warning(data.msg);
        }
        // TODO: could do a little more/better, above, dedicated route for details
        setTimeout(() => this.setState(prevState => ({...data.result, isContentLoading: false})), 300);
      })
      .catch(err => {
        this.setState({isLoading: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  initMessage() {
    const sender = JSON.parse(localStorage.getItem('start_dev'));
    const sendObj = {senderId: sender.id, id: this.state.id, subject: this.state.subject, message: this.state.message};
    restCall('/send-message/', 'POST', sender.reg_auth, sendObj)
      .then(res => res.json())
      .then(data => {
        if (!data.result) {
          signOut(2000); // Delay user before redirect
          return toaster.warning(data.msg);
        }
        return toaster.success('Your message has been sent.')
      })
      .catch(err => {
        this.setState({isLoading: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  render() {

    return (
      <Pane 
        className="flex-grid m-pane-out"
        alignItems="top"
        justifyContent="center"
        padding={25}>
        <Pane
          className="flex-grid"
          flexBasis={850}
          alignItems="top"
          justifyContent="center"
          marginBottom={25}
          padding={10}>
          <Pane width="100%">
            <Heading 
              size={700}
              marginBottom={15}>
              Details
            </Heading>
            {/* isContentLoading */}
            {this.state.isContentLoading && <Pane
              className="pane-el m-pane-el"
              elevation={1}
              width="100%"
              padding={50}>
                <Spinner marginX="auto"/>
            </Pane>}
            {!this.state.isContentLoading && <Pane
              className="pane-el m-pane-el"
              elevation={1}
              width="100%"
              padding={50}>
              <IconButton
                icon="arrow-left"
                marginBottom={30}
                onClick={() => this.props.history.goBack()}/>
              <Pane
              height={1}
              marginBottom={25}
              background="#EDF0F2"/>
              <Heading size={800}>
                {(this.state.type === 'startup' && this.state.companyname !== undefined && this.state.companyname !== '') ? this.state.companyname :this.state.firstname + ' ' + this.state.lastname}
              </Heading>
              <Heading size={400} marginBottom={20}>
                {this.state.city + ', '}{(states[this.state.state]) ? states[this.state.state] : '' }
              </Heading>

              {(this.state.type === 'developer' && this.state.employer !== undefined && this.state.employer !== '') &&
                <Pane marginBottom={20}>
                  <Heading size={100} marginBottom={0}>
                    Current Employer:
                  </Heading>
                  <Paragraph size={500} marginBottom={0}>{this.state.employer}</Paragraph>
                </Pane>
              }

              <Pane marginBottom={20}>
                <Heading size={100} marginBottom={0}>
                  LinkedIn:
                </Heading>
                <Link is="a" href={this.state.linkedin} target="blank">{ this.state.linkedin }</Link>
              </Pane>

              {(this.state.years !== undefined && this.state.years !== '') &&
                <Pane marginBottom={20}>
                  <Heading size={100} marginBottom={0}>
                    Years as {this.state.type}/{this.state.type === 'startup' ? 'Company' : 'Software Engineer'}
                  </Heading>
                  <Badge color="blue">{this.state.years}</Badge>
                </Pane>
              }

              { this.state.languageLabels.length > 0 && 
                <Pane marginBottom={20}>
                  <Heading size={100} marginBottom={0}>
                    Languages:
                  </Heading>
                  <Pane>
                    <Badges arr={this.state.languageLabels} mr={5} />
                  </Pane>
                </Pane> 
              }
              { this.state.description &&
                <Pane
                  marginBottom={20}>
                  <Heading size={100} marginBottom={5}>
                    Introduction:
                  </Heading>
                  <Pane
                    padding={10}
                    borderRadius={5}
                    background="tint1">
                    <Paragraph size={500}>{this.state.description}</Paragraph>
                  </Pane>
                </Pane>
              }
                
                <Pane>
                  <Button 
                    appearance="primary"
                    onClick={ () => this.setState({isShown: true}) }>
                    Message
                  </Button>
                </Pane>

                <Dialog
                  isShown={this.state.isShown}
                  title="Message"
                  onCloseComplete={() => {
                    if (!this.state.isInvalid)
                      this.initMessage();
                    this.setState({isShown: false}) 
                  }}
                  isConfirmDisabled={this.state.isInvalid}
                  isConfirmLoading={this.state.isLoading}
                  onConfirm={() => this.setState({isShown: false})}
                  confirmLabel="Send">

                  <TextInputField
                    label="Subject"
                    placeholder="Subject"
                    onChange={e => this.setState({subject: e.target.value})}
                    onBlur={this.isSubjectDirty ? () => false : () => {
                              this.setState({errors: { ...this.state.errors, subject: isGreaterThan(this.state.subject) }})
                              this.setState({ isInvalid: isFormInvalid({errors: this.state.errors, message: this.state.message, subject: this.state.subject})})
                              this.isSubjectDirty = true }}
                    onKeyUp={this.isSubjectDirty ? debounce(() => {
                              this.setState({errors: { ...this.state.errors, subject: isGreaterThan(this.state.subject) }})
                              this.setState({ isInvalid: isFormInvalid({errors: this.state.errors, message: this.state.message, subject: this.state.subject})})
                              }, 100) : () => false }
                    isInvalid={this.state.errors.subject}
                    validationMessage={this.state.errors.subject ? 'Subject must be at least 2 characters' : false}
                    required />
                  <Pane>
                    <Label
                      htmlFor="textarea"
                      marginBottom={4}
                      display="block" required>
                      Message *
                    </Label>
                    <Textarea
                      id="textarea"
                      placeholder="Message"
                      spellCheck={true}
                      grammarly={true}
                      onChange={e => this.setState({message: e.target.value})}
                      onBlur={this.isMessageDirty ? () => false : () => {
                                this.setState({errors: { ...this.state.errors, message: isGreaterThan(this.state.message) }})
                                this.setState({ isInvalid: isFormInvalid({errors: this.state.errors, message: this.state.message, subject: this.state.subject})})
                                this.isMessageDirty = true;
                              }}
                      onKeyUp={this.isMessageDirty || this.state.message > 2 ? () => false : debounce(() => {
                                this.setState({errors: { ...this.state.errors, message: isGreaterThan(this.state.message) }})
                                this.setState({ isInvalid: isFormInvalid({errors: this.state.errors, message: this.state.message, subject: this.state.subject})})
                              }, 100)}
                      isInvalid={this.state.errors.message}
                      required/>
                  </Pane>
              </Dialog>
                   
            </Pane>}
          </Pane>
        </Pane>
      </Pane>
    )
  }
}

export default Details