import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './index.css';

import Provider  from './context/context';
import Header from './components/Header';
import Footer from './components/Footer';
import Reg from './components/Registration';
import Profile from './components/Profile';
import List from './components/List';
import Details from './components/Details';
import Verify from './components/Verify';
import ResetPassword from './components/ResetPassword';
import Messages from './components/Messages';
import Message from './components/Message';
import About from './components/About';

import * as serviceWorker from './serviceWorker';

const Container = () => {
  return(
    <div>
      <Router>
        <div>
          <Provider>
            <Header/>
            <Switch>
              <Route path="/" exact component={Reg}/>
              <Route path="/about/" exact component={About}/>
              <Route path="/profile/:id" component={Profile}/>
              <Route path="/message/:id" component={Message}/>
              <Route path="/list/:query/" exact component={List}/>
              <Route path="/list/:query/:id" exact component={Details}/>
              <Route path="/messages/:id" component={Messages}/>
              <Route path="/verify/:id" component={Verify}/>
              <Route path="/reset-password/:id" component={ResetPassword}/>
            </Switch>
          </Provider>
          <Footer/>
        </div>
      </Router>
    </div>
  );
};

ReactDOM.render(<Container />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();