import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { restCall } from '../services/rest';
import { signOut, states } from '../services/utils';
import { Badges } from './Badges';
import { Pane, Heading, Card, toaster, Avatar, Text, Button, Icon, SelectField, TextInputField, TagInput, Label, Switch, IconButton, Pill, Badge, Spinner } from 'evergreen-ui';


class List extends Component {  

  constructor(props) {
    super(props);
    this.DEVELOPER = 'developers';
    this.tokenId = JSON.parse(localStorage.getItem('start_dev'));
    this.MAX_USERS_INT = 18; // Max users per page
    this.MILES = [25, 50, 75, 100, 150, 200, 250, 500, 1000]; // Miles for regoin queries

    this.state = {
      type: (window.location.pathname.split('/')[2] === this.DEVELOPER) ? 'developer' : 'startup',
      users: [],
      userCount: 0,
      state: this.getQueryParams().state ? this.getQueryParams().state : 'AL',
      city: this.getQueryParams().city ? this.getQueryParams().city : '',
      distance: this.getQueryParams().distance ? this.getQueryParams().distance : 25,
      languages: this.getQueryParams().languages ? this.getQueryParams().languages.split(','): [],
      isDisabledRegion: this.getQueryParams().state ? true : false, // False positive, due to Switch...
      isDisabledLanguages: this.getQueryParams().languages ? true: false,
      isLoading: true,
      isContentLoading: true
    }; 
  }

  componentDidMount() {
    this.getList();
  }

  componentWillReceiveProps() {
    this.setState({type: (window.location.pathname.split('/')[2] === this.DEVELOPER) ? 'developer' : 'startup'}, () => this.getList());
  }

  getList() { // Get the list of users
    const queryParams = this.getQueryParams();
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    this.setState({isLoading: true, isContentLoading: true});
    restCall('/list/', 'POST', tokenId.reg_auth, {query: {type: this.state.type, queryParams: queryParams}})
      .then(res => res.json())
      .then(data => {
        
        this.setState({isLoading: false, disabled: true, isContentLoading: true});
        if (!data.result && data.status === 403) {
          signOut(2000) // Delay user before redirect
          return toaster.warning(data.msg);
        }
        if (!data.result) {
          setTimeout(() => this.setState({users: [], userCount: data.count, isLoading: false, isContentLoading: false}), 300);
          return toaster.warning(data.msg);
        }
        setTimeout(() => this.setState({users: data.result, userCount: data.count, isLoading: false, isContentLoading: false}), 300);
      })
      .catch(err => {
        this.setState({isLoading: false, disabled: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  displayingNumOfUsers = (page, total, itemsOnPage) => { // Display number of user on page from total users
    const start = total === 0 ? 0 : (page - 1) * itemsOnPage + 1;
    const end = this.MAX_USERS_INT > itemsOnPage ? Math.max(page * itemsOnPage, total) : Math.min(page * itemsOnPage, total);
    return `${start} - ${end}`;
  }

  getPageNum() { // Get page number
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('page');
  }

  getQueryParams() { // Get all query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const objParams = {};
    [...urlParams.entries()].map(arr => arr.reduce((left, right) => objParams[left] = right));
    return objParams;
  }

  buildQueryParams() { // Build query parameters
    let queryObj = {};
    if (this.state.isDisabledRegion) {
      queryObj = {state: this.state.state, city: this.state.city, distance: this.state.distance}
    }
    if (this.state.isDisabledLanguages && this.state.languages.length > 0) {
      queryObj.languages = this.state.languages;
    }
    const urlParams = new URLSearchParams(queryObj);
    return urlParams.toString() ? '&' + urlParams.toString() : '';    
  }

  render() {
    return (
      <Pane 
        className="flex-grid m-pane-out"
        alignItems="top"
        justifyContent="center"
        padding={25}>

        <Pane
          className="flex-grid"
          flexBasis={850}
          alignItems="top"
          justifyContent="center"
          marginBottom={25}
          padding={10}
        >
          <Pane width="100%">
            <Heading 
              size={700}
              marginBottom={15}>
              { (this.state.type === 'developer') ? 'Developers' : 'Startups' }
            </Heading>
            <Pane
            className="pane-el m-pane-el"
            elevation={1}
            padding={35}>
              <Pane
                padding={10}
                marginBottom={15}
                borderRadius={5}
                background={'tint1'}>
                  <Switch
                    display="inline-block"
                    top="6px"
                    height={20}
                    marginRight={8}
                    checked={this.state.isDisabledRegion}
                    onChange={e => this.setState({isDisabledRegion: e.target.checked})}/>
                  <Label
                    marginBottom={5}
                    display="inline-block">
                      Search by region
                  </Label>
                <Pane
                  display="flex"
                  alignItems="flex-start"
                  className={!this.state.isDisabledRegion ? "wrap-labels accordion-hide" : "wrap-labels accordion-show"}>
                    {/* States selector */}
                  <SelectField
                    height={10}
                    disabled={!this.state.isDisabledRegion}
                    label={"States"}
                    value={this.state.state}
                    onChange={e => this.setState({state: e.target.value})}
                    width="33.333%"
                    marginRight={5}>
                      {Object.keys(states).map((val, idx) => {
                        return <option value={val} key={idx}>{states[val]}</option>;
                      })}
                  </SelectField>
                  <TextInputField
                      disabled={!this.state.isDisabledRegion}
                      label="City"
                      value={this.state.city}
                      onChange={e => this.setState({city: e.target.value})}
                      width="33.333%"
                      marginRight={5}/>
                      {/* Distance selector */}
                  <SelectField
                    disabled={!this.state.isDisabledRegion}
                    label={"Distance"}
                    onChange={e => this.setState({distance: e.target.value})}
                    width="33.333%"
                    value={this.state.distance}
                    marginRight={5}>
                      {this.MILES.map((val, idx) => {
                        return <option value={val} key={idx}>{val + ' miles'}</option>;
                      })}
                  </SelectField>
                </Pane>
              </Pane>
              <Pane
                padding={10}
                marginBottom={15}
                borderRadius={5}
                background={'tint1'}>
                <Switch
                  display="inline-block"
                  top="6px"
                  height={20}
                  marginRight={8}
                  checked={this.state.isDisabledLanguages}
                  onChange={e => this.setState({isDisabledLanguages: e.target.checked})}/>
                <Label
                    marginBottom={5}
                    display="inline-block">
                      Search by programming language
                </Label>
                <Pane
                  display="flex"
                  alignItems="flex-start"
                  className={!this.state.isDisabledLanguages ? "wrap-labels accordion-hide" : "wrap-labels accordion-show"}>
                  <Pane>
                    <Label
                      display="block"
                      marginBottom={5}>
                        Languages</Label>
                    <TagInput
                      disabled={!this.state.isDisabledLanguages}
                      initialstate={{ languages: this.state.languages }}
                      inputProps={{ placeholder: 'Press enter to add...' }}
                      values={this.state.languages} 
                      onChange={val => {
                        if (this.state.languages.length + val.length >= 14 || val.length >= 8) // 7 lang queries max
                          return null;
                        this.setState({languages: val})
                      }} />
                    </Pane>
                </Pane>
              </Pane>
              {/* Search button.. */}
              <Pane
                display="flex"
                alignItems="center"
                marginBottom={15}>
                <Link to={{search: 'page=1'}}>
                  <IconButton
                      icon="undo"
                      intent="none"
                      iconSize={12}
                      marginRight={5}
                      disabled={this.state.isLoading}
                      onClick={() => {
                        this.setState({isDisabledRegion: false, isDisabledLanguages: false})
                      }}/>
                </Link>
                <Link to={{search: `page=1${this.buildQueryParams()}`}}>
                  <Button
                    appearance="primary"
                    marginRight={10}
                    isLoading={this.state.isLoading}
                    disabled={!this.state.isDisabledRegion && !this.state.isDisabledLanguages}>
                      Search</Button>
                </Link>
                <Text size={400} marginLeft="auto">{!this.state.isDisabledRegion ? 'Sorted by last login' : 'Sorted by closest distance'}</Text>
              </Pane>
              {/* Page total and displaying */}
              <Pane
                marginBottom={15}>
                <Heading
                  size={200}>
                    Results: <Pill display="inline-flex" color="neutral" isSolid marginRight={5}>{this.state.userCount}</Pill> Showing: {this.displayingNumOfUsers(+this.getPageNum(), this.state.userCount, this.state.users.length)}
                </Heading>
              </Pane>
              
              <Pane clearfix
                display="flex"
                width="auto"
                flexDirection="row"
                flexWrap="wrap"
                marginBottom={5}>
                {/* isContentLoading */}
                {this.state.isContentLoading && 
                  <Pane
                    className="flex-grid"
                    display="flex"
                    flex={1}
                    flexBasis="100%"
                    flexFlow="column wrap"
                    marginRight={10}
                    marginBottom={15}>
                    <Card
                      elevation={1}
                      padding={15}
                      className="transition-common">
                      <Spinner marginX="auto"/>
                    </Card>
                  </Pane>}
                {/* Card repeat */}
                { this.state.users.map(((val, idx) => {
                return  <Pane
                          key={idx}
                          className="flex-grid"
                          display="flex"
                          flex={1}
                          flexBasis="25%"
                          flexFlow="column wrap"
                          marginRight={10}
                          marginBottom={15}>
                          
                          {!this.state.isContentLoading && <Link to={'/list/' + val.type + '/' + val.id}>
                            <Card
                                elevation={1}
                                padding={15}
                                className="transition-common card-hover">
                              {val.dist >= 0 ? <Heading size={200} className="heading-dist">
                                  {Math.floor(val.dist)} mi.
                                </Heading> : ''}
                              <Pane clearfix>
                                <Pane
                                  float="left"
                                  width="auto"
                                  display="flex"
                                  marginRight={5}
                                  marginBottom={8}>
                                  <Avatar size={40} key={idx} name={(val.type === 'startup' && val.companyname !== undefined && val.companyname !== '') ? val.companyname : val.firstname + ' ' + val.lastname }/>
                                </Pane>
                                <Pane align="left"
                                  float="left"
                                  width="auto"
                                  display="flex"
                                  marginTop={1}>
                                  <Heading size={400}>
                                    {(val.type === 'startup' && val.companyname !== undefined && val.companyname !== '') ? val.companyname : val.firstname + ' ' + val.lastname}
                                    <Text size={300} className="d-block" key={idx}>{ val.city + ', ' + val.state}</Text>
                                  </Heading>
                                </Pane>
                              </Pane>

                              { val.years ? 
                                <Pane marginBottom={3}>
                                  <Heading size={100}>
                                    {val.type === 'startup' ? 'Years active:' : 'Years experience:'}
                                    <Badge marginLeft={5}>{val.years}</Badge>
                                  </Heading>
                                </Pane> 
                                : null }

                              { val.languageLabels && val.languageLabels.length > 0 ? 
                                <Pane>
                                  <Heading size={100} marginBottom={5}>
                                    {val.type === 'startup' ? 'Company Stack:' : 'Languages:'}
                                  </Heading>
                                  <Pane>
                                    <Badges arr={val.languageLabels} mr={5} />
                                  </Pane>
                                </Pane> 
                                : null }
                            </Card>
                          </Link>}
                        </Pane>
                }))}
                {/* End card repeat */}

              </Pane>
              <Pane
                display="flex"
                justifyContent= "space-between">
                {/* TODO: minor bug.. when available query but have not init'd search.. next back inits it..  */}
                <Link to={{search: `page=${+this.getPageNum() - 1}${this.buildQueryParams()}`}}>
                  <Button
                    appearance="minimal"
                    intent="none"
                    className={+this.getPageNum() <= 1 ? "d-hide" : ""}
                    isLoading={this.state.isLoading} // Keep or leave?
                    disabled={+this.getPageNum() <= 1 }
                    onClick={() => this.getList()}>
                    <Icon icon="arrow-left" color="selected" size={12} marginRight={5}/>Back
                  </Button>
                </Link>
                <Link to={{search: `page=${+this.getPageNum() + 1}${this.buildQueryParams()}`}}>
                  <Button
                    appearance="minimal"
                    intent="none"
                    className={this.state.userCount <= (+this.getPageNum() * this.MAX_USERS_INT) ? "d-hide" : ""}
                    isLoading={this.state.isLoading} // Maybe keep or leave?
                    disabled={this.state.userCount < (+this.getPageNum() * this.MAX_USERS_INT)} //TODO: << Little more math over here.. I think
                    onClick={() => this.getList()}>
                    Next <Icon icon="arrow-right" color="selected" size={12} marginLeft={5}></Icon>
                  </Button>
                </Link>
              </Pane>
            </Pane>
            </Pane>
          </Pane>
        </Pane>
    );
  }
}

export default List;