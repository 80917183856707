import React, { Component } from 'react';
import Auth from './Auth';
import { Pane, Heading, IconButton, Paragraph, Link, UnorderedList, ListItem, Icon} from 'evergreen-ui';
import { loggedIn } from '../services/utils';

class About extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div>
        {!loggedIn().isLoggedIn && <Auth/> /* If not logged in show Auth header */}
        <Pane 
          className="flex-grid m-pane-out"
          alignItems="top"
          justifyContent="center"
          padding={25}>
          <Pane
            className="flex-grid"
            flexBasis={850}
            alignItems="top"
            justifyContent="center"
            marginBottom={25}
            padding={10}>
            <Pane width="100%">
              <Heading 
                size={700}
                marginBottom={15}>
                About
              </Heading>
              <Pane
                className="pane-el m-pane-el"
                elevation={1}
                width="100%"
                padding={50}>
                <IconButton
                  intent="none"
                  icon="arrow-left"
                  marginBottom={25}
                  onClick={() => this.props.history.goBack()}/>
                
                <Pane
                  height={1}
                  marginBottom={25}
                  background="#EDF0F2"/>
                  <Paragraph
                    size={500}
                    marginBottom={20}>
                    <strong>TheVettd</strong> came about from not being able to find a dedicated community solely made up of Software Engineers and Companies/Startups that have a need for software. 
                  </Paragraph>
                  <Paragraph 
                    size={500}
                    marginBottom={25}>
                    In the software landscape today that is filled with recruiters and talent agencies that may propose opportunities that may not be in the best interest of the Developer and/or Company at all times. I would like to connect the Company with the Developer directly and cut out all the empty calories in the process.
                  </Paragraph>
                  <UnorderedList
                    marginBottom={25}
                    icon="tick-circle"
                    iconColor="success">
                    <ListItem>
                        Search for Company and/or Developer within a specific region and/or specific languages
                    </ListItem>
                    <ListItem>
                        Review profile, review LinkedIn for more details
                    </ListItem>
                    <ListItem>
                        Message directly and connect
                    </ListItem>
                  </UnorderedList>
                  <Pane
                  height={1}
                  marginBottom={25}
                  background="#EDF0F2"/>
                  <Paragraph
                    size={500}
                    marginBottom={20}>
                    <strong>Vetting process:</strong> <br/>
                    The vetting process is a simple overview of skills shared on LinkedIn. Does not matter if you have one year experience or thirty. Same applies to Companies/Startups.
                  </Paragraph>
                  <Paragraph
                    size={500}
                    marginBottom={20}>
                    <strong>Sign up for free</strong> and find a vetted Software Engineer directly with the skill sets matching your Companies stack or as a Software Engineer find a vetted Company/Startup with a stack matching your skills.
                  </Paragraph>
                  <Paragraph
                    size={500}
                    marginBottom={!loggedIn().isLoggedIn ? 20 : 0}>
                    If you have any questions and/or things you would like to see, please <Link href="mailto:thevettd@gmail.com?subject=Idea...">contact me</Link>.
                  </Paragraph>
                  {!loggedIn().isLoggedIn && <Paragraph
                    size={500}
                    marginBottom={0}>
                    <Link href="/">Sign Up <Icon icon="arrow-right" size={10}/></Link>
                  </Paragraph>}
                </Pane>
            </Pane>
          </Pane>
        </Pane>
      </div>
    )
  }

}

export default About;