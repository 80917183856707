import React, { Component } from 'react';
import { Link as LinkAlt } from 'react-router-dom';
import { Pane, Text, UnorderedList, ListItem, Link, Icon } from 'evergreen-ui';

class Footer extends Component {

  render() {
    return (
      <Pane
        background="tint1"
        border="default"
        borderRadius={3}
        padding={20}>
          <Pane
            margin="auto"
            maxWidth={830}
            display="flex"
            height="100%"
            justifyContent="center"
            className="flex-grid m-pane-out footer">
            <Pane flex={1}
              alignSelf="center">
              <LinkAlt to="/">
                <img className="" src="/img/thevettd-footer.svg" alt="TheVettd footer logo"/>
              </LinkAlt>
            </Pane>
            <Pane flex={1}>
              <UnorderedList
                icon="blank"
                size={300}>
                {(window.innerWidth > 750) && <ListItem>
                  <strong>Community</strong>
                </ListItem>}
                <ListItem>
                  <LinkAlt to="/about/" className="footer-link">About</LinkAlt>
                </ListItem>
                <ListItem>
                  <Link href="https://www.linkedin.com/company/thevettd" target="_blank" className="footer-link">LinkedIn <Icon icon="arrow-top-right" size={9}/></Link>
                </ListItem>
                <ListItem>
                  <Link href="mailto:thevettd@gmail.com?subject=Contacting" className="footer-link">Contact</Link>
                </ListItem>
              </UnorderedList>
            </Pane>
            <Pane flex={2}>
            <UnorderedList
                icon="blank"
                size={300}>
                {(window.innerWidth > 750) && <ListItem>
                  <strong>Mission</strong>
                </ListItem>}
                <ListItem>
                  <Text size={300}>
                    A community soley based on connecting a vetted Developer directly with a vetted Company (or vice versa) without the hassle of the connecting third party.
                  </Text>
                </ListItem>
              </UnorderedList>
            </Pane>
          </Pane>
        </Pane>
    );
  }
}

export default Footer;