import React, { Component } from 'react';
import { restCall } from '../services/rest';
import { validatePassword, isFormInvalid } from '../services/utils';
import { Pane, Heading, Paragraph, toaster, Link, TextInputField, Button } from 'evergreen-ui';
import debounce from 'debounce';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.token = window.location.pathname.split('/')[2];
    this.userid = window.location.pathname.split('/')[3];
    this.isPwDirty = false;
    this.isConfirmPwDirty = false;

    this.state = {
      password: '',
      confirmPassword: '',
      errors: {password: false, confirmPassword: false},
      isInvalid: true,
      isLoading: false,
      isSessionValid: true,
      isSuccess: false
    };
  }

  componentDidMount() {
    restCall('/reset-password/', 'POST', null, {id: this.userid, token: this.token})
      .then(res => res.json())
      .then(data => {
        if (!data.result) {
          this.setState({isSessionValid: false})
          return toaster.warning(data.msg);
        }
      })
      .catch(err => {
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  initResetPassword() {
    this.setState({isLoading: true})
    if (!this.state.isInvalid) {
      let authObj = Object.assign({}, this.state);
      authObj = {password: authObj.password, id: this.userid};
      restCall('/reset-password/', 'POST', null, authObj)
        .then(res => res.json())
        .then(data => {
          this.setState({isLoading: false, isInvalid: true});
          if (!data.result)
            return toaster.warning(data.msg);
          this.setState({isSuccess: true});
          toaster.success(data.msg);
        })
        .catch(err => {
          this.setState({isLoading: false});
          toaster.notify('Sorry, something went wrong, please try back later');
          console.error('Err ', err);
        });
    }
  }

  validateConfirmPassword() { // validates and matches password
    return (!validatePassword(this.state.confirmPassword) && (this.state.password === this.state.confirmPassword));
  }

  resetPasswordInputs() {
    const RESET_PASSWORD_INPUTS = [{
      label: 'Password',
      type: 'password',
      name: 'password',
      disabled: !this.state.isSessionValid || this.state.isSuccess,
      value: this.state.password,
      onChange: e => this.setState({password: e.target.value}),
      onBlur: this.isPwDirty ? () => false : debounce(() => { 
                                                this.setState({errors: { ...this.state.errors, password: validatePassword(this.state.password) }})
                                                this.setState({ isInvalid: isFormInvalid({email: this.state.email, password: this.state.password, errors: this.state.errors})})
                                                this.isPwDirty = true;
                                              },100),
      onKeyUp: (this.isPwDirty || this.state.password.length > 8) ? debounce(() => {
                  this.setState({errors: { ...this.state.errors, password: validatePassword(this.state.password) }})
                  this.setState({ isInvalid: isFormInvalid({confirmPassword: this.state.confirmPassword, password: this.state.password, errors: this.state.errors})})
                }, 1500) : () => false,
      isInvalid: this.state.errors.password,
      validationMessage: this.state.errors.password ? 'Please provide valid password' : false,
      hint: 'Password must be a minimum of eight characters and at least one digit and capital letter'
    },
    {
      label: 'Confirm Password',
      type: 'password',
      name: 'confirmPassword',
      disabled: !this.state.isSessionValid || this.state.isSuccess,
      value: this.state.confirmPassword,
      onChange: e => this.setState({confirmPassword: e.target.value}),
      onBlur: this.isConfirmPwDirty ? () => false : debounce(() => { 
                                                this.setState({errors: { ...this.state.errors, confirmPassword: !this.validateConfirmPassword() }})
                                                this.setState({ isInvalid: isFormInvalid({confirmPassword: this.state.confirmPassword, password: this.state.password, errors: this.state.errors})})
                                                this.isConfirmPwDirty = true;
                                              },100),
      onKeyUp: (this.isConfirmPwDirty || this.state.confirmPassword.length > 8) ? debounce(() => {
                  this.setState({errors: { ...this.state.errors, confirmPassword: !this.validateConfirmPassword() }})
                  this.setState({ isInvalid: isFormInvalid({confirmPassword: this.state.confirmPassword, password: this.state.password, errors: this.state.errors})})
                }, 1500) : () => false,
      isInvalid: this.state.errors.confirmPassword,
      validationMessage: this.state.errors.confirmPassword ? 'Please provide valid password and must match above password' : false,
    }];
    return RESET_PASSWORD_INPUTS;
  }

  render() {

    return (

      <Pane 
        className="flex-grid"
        alignItems="top"
        justifyContent="center"
        padding={25}>

        { this.state.isSessionValid && <Pane
          className="flex-grid"
          flexBasis={850}
          alignItems="top"
          justifyContent="center"
          marginBottom={25}
          padding={10}
        >
          <Pane width="100%">
            <Heading 
              size={700}
              marginBottom={15}
              >
              Reset Password
            </Heading>
            <Pane
              className="pane-el m-pane-el"
              elevation={1}
              padding={50}>
            <Pane
              width="65%">
              {this.state.isSuccess && 
                <Pane
                  marginBottom={25}>
                  <Link is="a" href="/" target="_blank">Login</Link>
                </Pane>
              }
              <Paragraph
                marginBottom={25}>
                Password must be a <strong>minimum of eight characters</strong> and at least <strong>one digit</strong>, <strong>capital letter</strong> and <strong>special character (`~!@#$%^&*)</strong>.
              </Paragraph>
              {
                this.resetPasswordInputs().map((value, index) => {
                  return  <TextInputField key={index} {...value} required/>
                })
              }
            </Pane>
            <Button
              disabled={this.state.isInvalid || this.state.password === '' || this.state.confirmPassword === '' || !this.validateConfirmPassword() || this.state.isSuccess}
              isLoading={this.state.isLoading}
              onClick={() => this.initResetPassword()}
              appearance="primary">
              Reset
            </Button>
            </Pane>
          </Pane>
        </Pane>}
      </Pane>
    );
  }
}

export default ResetPassword;