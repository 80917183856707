import React, { Component } from 'react';
import { Redirect, Link as LinkAlt } from 'react-router-dom';
import { validateEmail, validatePassword, isFormInvalid } from '../services/utils';
import { restCall } from '../services/rest';
import { Button, Pane, TextInputField, toaster, Dialog, Link } from 'evergreen-ui';
import debounce from 'debounce';

class Auth extends Component {
  constructor(props) {
    super(props);

    this.authInit = this.authInit.bind(this);
    this.resetInit = this.resetInit.bind(this);
    this.isEmailDirty = false;
    this.isPwDirty = false;
    this.isEmailPasswordResetDirty = false;

    this.state = {
      email: '',
      password: '',
      emailPasswordReset: '',
      isShown: false,
      errors: { email: false, password: false, emailPasswordReset: false},
      isInvalid: true,
      isLoading: false,
      isLogin: true,
      redirect: false,
      isInitiating: false
    };
    this.initialState = Object.assign({}, this.state);
  }

  authInit(close) {
    if (!this.state.isInvalid) {
      let authObj = Object.assign({}, this.state);
      authObj = {email: authObj.email, password: authObj.password};
      restCall('/auth/', 'POST', null, authObj)
        .then(res => res.json())
        .then(data => {
          this.setState({isLoading: false, isInitiating: false});
          if (!data.result)
            return toaster.warning(data.msg);
          close();
          setTimeout(() => toaster.success(data.msg), 400);
          window.localStorage.setItem('start_dev', JSON.stringify({reg_auth: data.token, id: data.id}));
          setTimeout(() => this.setState({redirect: true, id: data.id }) ,350) // Hack, think it works oppose to unmount
        })
        .catch(err => {
          this.setState({isLoading: false, isInitiating: false});
          toaster.notify('Sorry, something went wrong, please try back later');
          console.error('Err ', err);
        });
    }
  }

  resetInit(close) {
    if (!this.state.isInvalid) {
      let authObj = Object.assign({}, this.state);
      authObj = {email: authObj.emailPasswordReset};
      restCall('/reset-password/', 'POST', null, authObj)
        .then(res => res.json())
        .then(data => {
          close();
          this.setState({isLoading: false, isInitiating: false});
          if (!data.result)
            return toaster.warning(data.msg);
          toaster.success(data.msg, {description: data.description, duration: 15});
        })
        .catch(err => {
          this.setState({isLoading: false, isInitiating: false});
          toaster.notify('Sorry, something went wrong, please try back later');
          console.error('Err ', err);
        });
    }
  }

  authInputs() {
    const AUTH_INPUTS = [{
        label: 'Email',
        type: 'email',
        name: 'email',
        value: this.state.email,
        className: this.state.isLogin ? '' : 'd-hide',
        onChange: e => this.setState({email: e.target.value}),
        onBlur: this.isEmailDirty  ? () => false : debounce(() => { 
                                                      this.setState({errors: { ...this.state.errors, email: validateEmail(this.state.email) }})
                                                      this.setState({ isInvalid: isFormInvalid({email: this.state.email, password: this.state.password, errors: this.state.errors})})
                                                      this.isEmailDirty = true;
                                                    },100),
        onKeyUp: this.isEmailDirty ? debounce(() => {
                                      this.setState({errors: { ...this.state.errors, email: validateEmail(this.state.email) }})
                                      this.setState({ isInvalid: isFormInvalid({email: this.state.email, password: this.state.password, errors: this.state.errors})})
                                    },100) 
                                  : () => false,
        isInvalid: this.state.errors.email,
        validationMessage: this.state.errors.email ? 'Please provide valid email' : false,
        disabled: this.state.isInitiating
      },
      {
        label: 'Password',
        type: 'password',
        name: 'password',
        value: this.state.password,
        className: this.state.isLogin ? '' : 'd-hide',
        onChange: e => this.setState({password: e.target.value}),
        onBlur: this.isPwDirty ? () => false : debounce(() => { 
                                                  this.setState({errors: { ...this.state.errors, password: validatePassword(this.state.password) }})
                                                  this.setState({ isInvalid: isFormInvalid({email: this.state.email, password: this.state.password, errors: this.state.errors})})
                                                  this.isPwDirty = true;
                                                },100),
        onKeyUp: (this.isPwDirty || this.state.password.length > 8) ? debounce(() => {
                    this.setState({errors: { ...this.state.errors, password: validatePassword(this.state.password) }})
                    this.setState({ isInvalid: isFormInvalid({email: this.state.email, password: this.state.password, errors: this.state.errors})})
                  }, 1500) : () => false,
        isInvalid: this.state.errors.password,
        validationMessage: this.state.errors.password ? 'Please provide valid password' : false,
        disabled: this.state.isInitiating
      },
      {
        label: 'Email',
        type: 'email',
        name: 'emailPasswordReset',
        value: this.state.emailPasswordReset,
        hint: 'To verify account, enter email in order to reset password.',
        className: !this.state.isLogin ? '' : 'd-hide',
        onChange: e => this.setState({emailPasswordReset: e.target.value}),
        onBlur: this.isEmailPasswordResetDirty   ? () => false : debounce(() => { 
                                                      this.setState({errors: { ...this.state.errors, emailPasswordReset: validateEmail(this.state.emailPasswordReset) }})
                                                      this.setState({ isInvalid: isFormInvalid({emailPasswordReset: this.state.emailPasswordReset, errors: this.state.errors})})
                                                      this.isEmailPasswordResetDirty = true;
                                                    },100),
        onKeyUp: (this.isEmailPasswordResetDirty || this.state.emailPasswordReset.length > 13) ? debounce(() => {
                                      this.setState({errors: { ...this.state.errors, emailPasswordReset: validateEmail(this.state.emailPasswordReset) }})
                                      this.setState({ isInvalid: isFormInvalid({emailPasswordReset: this.state.emailPasswordReset, errors: this.state.errors})})
                                    },1000) 
                                  : () => false,
        isInvalid: this.state.errors.emailPasswordReset,
        validationMessage: this.state.errors.emailPasswordReset ? 'Please provide valid email' : false,
        disabled: this.state.isInitiating
      }
    ];

    return AUTH_INPUTS;
  }

  render() {
    if (this.state.redirect)
      return <Redirect push to={{pathname: '/profile/' + this.state.id }} />;
    return (
        <Pane
          width="100%"
          padding={14}
          paddingBottom={12}
          elevation={0}>
          <Pane
            display="flex"
            margin="auto"
            alignItems="center"
            maxWidth={840}>
            <Pane
              display="flex"
              flex="1">
              <Pane
                paddingLeft={(window.innerWidth > 750) ? 6 : 3}>
                <LinkAlt
                  className="d-block"
                  to="/">
                  <img className="the-vettd-logo" src="/img/thevettd.svg" alt="TheVettd Logo"/>
                </LinkAlt>
              </Pane>
            </Pane>
            <Pane
              display="flex"
              alignItems="center">
                <Link
                  href="/about/"
                  marginRight={15}>
                  About
                </Link>
                <Link
                  href="mailto:thevettd@gmail.com?subject=Contacting thevettd.com"
                  marginRight={15}>
                  Contact
                </Link>
              <Button
                appearance="primary"
                marginRight={6}
                onClick={() => this.setState({isShown: true})}>
                Login
              </Button>
              <Dialog
                isShown={this.state.isShown}
                title={this.state.isLogin ? "Login" : "Forget Password/Reset Password"}
                onCloseComplete={e => {
                  this.setState({isShown: false, isLogin: true, email: '', password: '', emailPasswordReset: '', errors: { email: false, password: false, emailPasswordReset: false}})
                }}
                isConfirmDisabled={this.state.isInvalid}
                isConfirmLoading={this.state.isInitiating}
                onConfirm={close => {
                  this.setState({isInitiating: true});
                  setTimeout(() => { // Indicate to user initiating by timeout
                    if (this.state.isLogin) {
                      this.authInit(close);
                    } else {
                      this.resetInit(close);
                    }
                  }, 500)
                }}
                confirmLabel={this.state.isLogin ? "Login" : "Reset"}>
                {
                  this.authInputs().map((value, index) => {
                    return  <Pane className={value.className} key={index}>
                              <TextInputField {...value} required/>
                            </Pane>
                  })
                }
                <Link onClick={e => {
                  this.setState({isLogin: !this.state.isLogin, email: '', password: '', emailPasswordReset: '', errors: { email: false, password: false, emailPasswordReset: false}})
                }} size={300} className="cursor-pointer">{this.state.isLogin ? 'Forgot Password/Password Reset' : 'Login'}</Link>
            </Dialog>
            </Pane>
          </Pane>
        </Pane>

    );
  }
}

export default Auth;
