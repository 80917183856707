import React, { Component } from 'react';
import { states, validateEmail, isGreaterThan, isFormInvalid, isUrlValid } from '../services/utils';
import { Context }  from '../context/context';
import { Pane, Heading, Label, Button, SelectField, RadioGroup, TextInputField, Textarea, TagInput, Switch, Badge } from 'evergreen-ui';

class Profile extends Component {

  profileInputs() {
    const PROFILE_INPUTS = [{
                              label: 'First Name',
                              name: 'firstname',
                              type: 'text',
                          },
                          {
                            label: 'Last Name',
                            name: 'lastname',
                            type: 'text',
                          },
                          {
                            label: 'City',
                            name: 'city',
                            type: 'text',
                          },
                          {
                            label: '*State',
                            name: 'state',
                            type: 'select',
                            value: states
                          },
                          {
                            label: '*Please identify as one:',
                            name: 'type',
                            type: 'radio',
                            options: [{label: 'Company/Startup', value: 'startup'}, {label: 'Developer/Software Engineer', value: 'developer'}]
                          },
                          {
                            label: 'Startup/Company Name',
                            hint: 'Once entered, Company name will show up in search and messaging.',
                            name: 'companyname',
                            type: 'text',
                          },
                          {
                            name: 'years',
                            type: 'select',
                            value: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10+', '15+', '20+']
                          },
                          {
                            name: 'linkedin',
                            type: 'text',
                          },
                          {
                            label: 'Current Employer',
                            name: 'employer',
                            type: 'text',
                          },
                          {
                            label: 'Email',
                            name: 'email',
                            type: 'email',
                          },
                          {
                            name: 'languageLabels',
                            type: 'textarea',
                            placeholder: 'Please provide a list of your primary languages. Example: Javascript, C#, Java'
                          },
                          {
                            label: 'Introduction',
                            name: 'description',
                            type: 'textarea',
                            placeholder: 'Please provide a introduction of your Startup or a description of skills and intentions as a Developer.'
                          }];
    return PROFILE_INPUTS;
  }


  render() {
    return (
      <Pane 
        className="flex-grid m-pane-out"
        alignItems="top"
        justifyContent="center"
        padding={25}>
        <Pane
          className="flex-grid"
          flexBasis={850}
          alignItems="top"
          justifyContent="center"
          marginBottom={25}
          padding={10}>
          <Pane width="100%">
            <Heading 
              size={700}
              marginBottom={15}
              >
              Profile
            </Heading>
            <Pane
                className="pane-el m-pane-el"
                elevation={1}
                width="100%"
                padding={50}              
              >
              {/* Consumer */}
              <Context.Consumer>
                {(context) => (
                  <React.Fragment>
                    <Pane clearfix>
                      <Button
                        float="right"
                        appearance={(context.disabled) ? "primary" : "default"}
                        onClick={() => context.onEdit(context.disabled)}>
                        { (context.disabled) ? 'Edit Profile' : 'Lock Profile'}
                      </Button>
                    </Pane>
                    <Pane
                      marginBottom={15}>
                      <Switch
                        display="inline-block"
                        disabled={context.disabled}
                        top="5px"
                        height={20}
                        marginRight={8}
                        checked={context.isActive}
                        onChange={e => context.setValue('isActive', e.target.checked)}/>
                      <Label
                      size={500}>
                        Show up in searches
                      </Label>
                    </Pane>

                    {
                      this.profileInputs().map((value, index) => {
                          if (value.type === 'select' && value.name === 'state') {
                            const selectHTML = <SelectField
                                                  label={value.label}
                                                  onChange={e => context.setValue(value.name, e.target.value)}
                                                  value={context.state}
                                                  key={index}
                                                  width="50%"
                                                  disabled={context.disabled}
                                                  marginBottom={25}>
                                                    {Object.keys(value.value).map((val, idx) => {
                                                      return <option value={val} key={idx}>{value.value[val]}</option>;
                                                    })}
                                                </SelectField>;
                            return selectHTML;
                          }
                          if (value.type === 'radio' && context.disabled === false) {
                            return <RadioGroup
                                      key={index}
                                      marginBottom={20}
                                      size={16}
                                      label={value.label}
                                      value={context[value.name]}
                                      options={value.options}
                                      onChange={e => {
                                        context.setValue([value.name], e)
                                        context.setErrors('linkedin', isUrlValid(context.linkedin, e, context.errors.linkedin))
                                        context.setValue('isInvalid', isFormInvalid({city: context.city, email: context.email, firstname: context.firstname, lastname: context.lastname, linkedin: context.linkedin, errors: {...context.errors, linkedin: isUrlValid(context.linkedin, e, context.errors.linkedin)}}))
                                      }}
                                      disabled/>;
                          }

                          if (value.type === 'radio' && context.disabled) {
                            return <Pane marginBottom={20} key={index}>
                                    <Label size={400}>You have identified as a: </Label>
                                    <Badge color="blue">{(context.type === 'developer') ? ' Developer' : ' Startup'}</Badge>
                                  </Pane>
                          }

                          
                          if (value.type === 'select' && value.name === 'years') {
                            return <SelectField
                                    label={'Years as ' + context.type }
                                    onChange={e => context.setValue(value.name, e.target.value)}
                                    value={context.years}
                                    key={index}
                                    width="25%"
                                    disabled={context.disabled}
                                    marginBottom={25}>
                                      {value.value.map((val, idx) => {
                                        return <option value={val} key={idx}>{val}</option>;
                                      })}
                                  </SelectField>
                          }
                          

                          if (value.name === 'languageLabels') {
                            return <Pane key={index} marginBottom={20}>
                                      <Label  display="block" marginBottom={4} htmlFor={value.name}>{context.type === 'developer' ? 'Primary Languages' : 'Company Stack'}</Label>
                                      <TagInput
                                        initialstate={{ values: context[value.name] }}
                                        disabled={context.disabled}
                                        inputProps={{ placeholder: 'Press enter to add..' }}
                                        values={context[value.name]}
                                        onChange={val => {
                                          if (context.languageLabels.length + val.length >= 14 || val.length >= 8) // 7 lang queries max
                                            return null;
                                          context.setValue(value.name, val)}
                                        }/>
                                    </Pane>
                          }

                          if (context.type === 'startup' && value.name === 'companyname') {
                            return  <TextInputField 
                                      key={index}
                                      {...value}
                                      onChange={e => context.setValue(value.name, e.target.value)}
                                      disabled={context.disabled}
                                      value={context[value.name]}/>;
                          }

                          if (context.type === 'developer' && value.name === 'employer') {
                            return  <TextInputField 
                                      key={index}
                                      {...value}
                                      onChange={e => context.setValue(value.name, e.target.value)}
                                      disabled={context.disabled}
                                      value={context[value.name]}/>;
                          }
                          if (context.type === 'startup' && value.name === 'employer') {
                            return null;
                          }

                          if (value.type === 'textarea') {
                            return <Pane key={index} marginBottom={20}>
                                    <Label  display="block" marginBottom={4} htmlFor={value.name}>{value.label}</Label>
                                     <Textarea
                                      disabled={context.disabled}
                                      value={context[value.name]}
                                      placeholder={value.placeholder}
                                      onChange={e => context.setValue(value.name, e.target.value)}
                                    />
                                  </Pane>
                          }

                          if (value.name === 'linkedin') {
                            return  <TextInputField 
                                    key={index}
                                    label={ context.type === 'developer' ? 'LinkedIn Profile' : 'LinkedIn Company Profile'}
                                    {...value}
                                    onChange={e => {
                                      context.setValue([value.name], e.target.value)
                                      context.setErrors([value.name], isUrlValid(e.target.value, context.type, context.errors.linkedin))
                                      context.setValue('isInvalid', isFormInvalid({city: context.city, email: context.email, firstname: context.firstname, lastname: context.lastname, linkedin: context.linkedin, errors: {...context.errors, linkedin: isUrlValid(e.target.value, context.type, context.errors.linkedin)}}))}
                                    }
                                    disabled={context.disabled}
                                    value={context[value.name]}
                                    onBlur={() => {
                                      context.setErrors([value.name], isUrlValid(context.linkedin, context.type, context.errors.linkedin))
                                      context.setValue('isInvalid', isFormInvalid({city: context.city, email: context.email, firstname: context.firstname, lastname: context.lastname, linkedin: context.linkedin, errors: context.errors}))}
                                    }
                                    onKeyUp={() => {
                                      context.setErrors([value.name], isUrlValid(context.linkedin, context.type, context.errors.linkedin))
                                      context.setValue('isInvalid', isFormInvalid({city: context.city, email: context.email, firstname: context.firstname, lastname: context.lastname, linkedin: context.linkedin, errors: context.errors}))}
                                    }
                                    isInvalid={context.errors[value.name]}
                                    validationMessage={ context.errors[value.name] ? context.errorMsg[value.type] : false}
                                    required/>;
                          }

                          if (context.type === 'developer' && value.name === 'companyname') {
                            return null;
                          }

                          return  <TextInputField 
                                    key={index}
                                    {...value}
                                    onChange={e => context.setValue(value.name, e.target.value)}
                                    disabled={context.disabled}
                                    value={context[value.name]}
                                    onBlur={() => {
                                      context.setErrors([value.name], (value.type === 'email') ? validateEmail(context[value.name]) : isGreaterThan(context[value.name]))
                                      context.setValue('isInvalid', isFormInvalid({city: context.city, email: context.email, firstname: context.firstname, lastname: context.lastname, linkedin: context.linkedin, errors: context.errors}))}
                                    }
                                    onKeyUp={() => {
                                      context.setErrors([value.name], (value.type === 'email') ? validateEmail(context[value.name]) : isGreaterThan(context[value.name]))
                                      context.setValue('isInvalid', isFormInvalid({city: context.city, email: context.email, firstname: context.firstname, lastname: context.lastname, linkedin: context.linkedin, errors: context.errors}))}
                                    }
                                    isInvalid={context.errors[value.name]}
                                    validationMessage={ context.errors[value.name] ? context.errorMsg[value.type] : false}
                                    required/>;
                      })
                    }
                    <Button
                      appearance={"primary"}
                      disabled={(context.disabled || context.isInvalid)}
                      isLoading={context.isLoading}
                      onClick={() => context.setUser(context)}>
                    Save Profile</Button>
                  </React.Fragment>
                    )}
              </Context.Consumer>
            {/* END Consumer */}
            </Pane>
            </Pane>
          </Pane>
        </Pane>
    );
  }
}

export default Profile;