import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Context }  from '../context/context';
import { loggedIn } from '../services/utils';
import { Pane, Avatar, Popover, Menu, Position, Button, Pill, Badge, Small } from 'evergreen-ui';

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id: '',
      redirectProfile: false,
    }
    this.ls = null; // localStorage, assign if header, userid
    this.PATH_STARTUPS = '/list/startups';
    this.PATH_DEVELOPERS = '/list/developers';
  } 

  render() {
    // Currently do not show if not logged in
    if (loggedIn().isLoggedIn)
      this.ls = loggedIn().ls;
      
    return (
      <div>
        { loggedIn().isLoggedIn &&    
          <Pane
            width="100%"
            padding={(window.innerWidth > 750) ? 10 : 5}
            elevation={0}
            clearfix>
            <Pane
              display="flex"
              margin="auto"
              alignItems="center"
              maxWidth={850}>
            <Pane
              display="flex"
              flex={1}
              paddingLeft={(window.innerWidth > 750) ? 6 : 3}>
              <Link className="d-block" to={"/profile/" + this.ls.id}>
                <img className="the-vettd-logo" src="/img/thevettd.svg" alt="TheVettd logo"/>
              </Link>
            </Pane>
            <Pane
              display="flex"
              alignItems="center">
            <Link to={{pathname: "/list/startups", search: "page=1"}}>
              <Button appearance="minimal" className="no-underlink" isActive={(window.location.pathname === this.PATH_STARTUPS)}>
                Startups
              </Button>
            </Link>
            <Link to={{ pathname: "/list/developers", search: "page=1"}}>
              <Button appearance="minimal" className="no-underlink" isActive={(window.location.pathname === this.PATH_DEVELOPERS)}>
                Developers
              </Button>
            </Link>

              {/* Consumer */}
                <Context.Consumer>
                  {(context) => (
                    <React.Fragment>
                      <Pane 
                        marginLeft={(window.innerWidth > 750) ? 15 : 3}
                        paddingLeft={(window.innerWidth > 750) ? 20 : 3}
                        borderLeft={(window.innerWidth > 750) ? 'muted' : false}>
                        <Popover
                          position={Position.BOTTOM_LEFT}
                          content={
                            <Menu>
                              <Menu.Group>
                                {/* Need to figure our routing below.. on click preferably */}
                                <Menu.Item><Link to={'/messages/' + context.messagesMappedId} className="d-block no-underlink-dk">Messages<Badge display="inline-flex" marginLeft={5}><Small>{context.messagesUnread} Unread</Small></Badge></Link></Menu.Item>
                                <Menu.Item><Link to={'/profile/' + context.id} className="d-block no-underlink-dk">Profile</Link></Menu.Item>
                                <Menu.Item onSelect={() => context.signOut(0) }><Link to={'/'} className="d-block no-underlink-dk">Sign Out</Link></Menu.Item>
                              </Menu.Group>
                            </Menu>
                          }>
                          <Pane>
                        {context.messagesUnread > 0 && <Pill display="inline-flex" className="notification">{context.messagesUnread}</Pill> }
                            <Avatar
                              className="cursor-pointer"
                              name={  context.firstname + ' ' + context.lastname}
                              size={40}
                              marginRight={10}/>
                          </Pane>
                        </Popover>
                      </Pane>
                </React.Fragment>
                  )}
                </Context.Consumer>
              {/* Consumer END */}
            </Pane>
            </Pane>
          </Pane> 
        }
        
      </div>
    );
  }

}

export default Header;