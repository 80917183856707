import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { restCall } from '../services/rest';
import { states } from '../services/utils';
import Auth from './Auth';
import { validateEmail, isGreaterThan, isFormInvalid, validatePassword, isUrlValid} from '../services/utils';
import { Button, Pane, Heading, TextInputField, SelectField, RadioGroup, toaster, Text } from 'evergreen-ui';
import debounce from 'debounce';


class Registration extends Component {
  constructor(props) {
    super(props);
    
    this.registerInit = this.registerInit.bind(this);

    this.isFirstNameDirty = false;
    this.isLastNameDirty = false;
    this.isEmailDirty = false;
    this.isPwDirty = false;
    this.isCityDirty = false;
    
    this.state = {
      userId: '',
      firstname: '',
      lastname: '',
      city: '',
      state: 'AL',
      type: 'startup',
      linkedin: '',
      email: '',
      password: '',
      errors: { firstname: false, lastname: false, city: false, email: false, password: false, linkedin: false },
      isInvalid: true,
      isLoading: false,
      redirect: false
    };
    this.initialState = Object.assign({}, this.state);
  }

  componentDidMount() {
    this.isAuthed();
  }

  isAuthed() { // If already authorized log user in 
    const ls = localStorage.getItem('start_dev') ? JSON.parse(localStorage.getItem('start_dev')) : '';
    if (ls !== '' && ls.reg_auth !== '') { // If already has token, try token. Otherwise, nothing
      restCall('/is-authed/', 'POST', ls.reg_auth, {})
        .then(res => res.json())
        .then(data => {
          if (!data.result && data.status === 403)
            return localStorage.removeItem('start_dev'); // if expirired token, remove item
          if (data.result) // true result, loggedin
            return this.setState({redirect: true, userId: ls.id});
        })
        .catch(err => {
          console.error('Err ', err);
        });
    }
  }
  
  registerInit() {
    this.setState({isLoading: true});
    let setobj = Object.assign({}, this.state);
    delete setobj.userId;
    delete setobj.errors;
    delete setobj.isInvalid;
    delete setobj.isLoading;

    // Production.. need to figure out URL
    restCall('/registration/', 'POST', null, setobj)
      .then(res => res.json())
      .then(data => {
        this.setState({isLoading: false});
        if (data.msg.responseCode === 553)
          return toaster.notify(`Your email, ${data.msg.rejected[0]} has been rejected`);
        if (!data.result)
          return toaster.notify(data.msg);
        this.setState(this.initialState);
        toaster.success(data.msg, {description: data.description, duration: 15});
      })
      .catch(err => {
        this.setState({isLoading: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  registrationInputs() {
    const REGISTRATION_INPUTS = [{
                                    label: 'First Name',
                                    name: 'firstname',
                                    type: 'text',
                                    value: this.state.firstname,
                                    onChange: e => this.setState({firstname: e.target.value}),
                                    onBlur: this.isFirstNameDirty ? () => false : () => {
                                                                    this.setState({errors: { ...this.state.errors, firstname: isGreaterThan(this.state.firstname) }})
                                                                    this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                    this.isFirstNameDirty = true;
                                                                  },
                                    onKeyUp: this.isFirstNameDirty ? debounce(() => {
                                                                    this.setState({errors: { ...this.state.errors, firstname: isGreaterThan(this.state.firstname) }})
                                                                    this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                    }, 300) : () => false,
                                    isInvalid: this.state.errors.firstname,
                                    validationMessage: this.state.errors.firstname ? 'First name must be at least 2 characters' : false
                                },
                                {
                                  label: 'Last Name',
                                  name: 'lastname',
                                  type: 'text',
                                  value: this.state.lastname,
                                  onChange: e => this.setState({lastname: e.target.value}),
                                  onBlur: this.isLastNameDirty ? () => false : () => {
                                                                  this.setState({errors: { ...this.state.errors, lastname: isGreaterThan(this.state.lastname) }})
                                                                  this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                  this.isLastNameDirty = true;
                                                                },
                                  onKeyUp: this.isLastNameDirty ? debounce(() => {
                                                                  this.setState({errors: { ...this.state.errors, lastname: isGreaterThan(this.state.lastname) }})
                                                                  this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                  }, 300) : () => false,
                                  isInvalid: this.state.errors.lastname,
                                  validationMessage: this.state.errors.lastname ? 'Last name must be at least 2 characters' : false
                                },
                                {
                                  label: 'City',
                                  name: 'city',
                                  type: 'text',
                                  value: this.state.city,
                                  onChange: e => this.setState({city: e.target.value}),
                                  onBlur: this.isCityDirty ? () => false : () => {
                                                                  this.setState({errors: { ...this.state.errors, city: isGreaterThan(this.state.city) }})
                                                                  this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                  this.isCityDirty = true;
                                                                },
                                  onKeyUp: this.isCityDirty ? debounce(() => {
                                                                  this.setState({errors: { ...this.state.errors, city: isGreaterThan(this.state.city) }})
                                                                  this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                  }, 300) : () => false,
                                  isInvalid: this.state.errors.city,
                                  validationMessage: this.state.errors.city ? 'City must be at least 2 characters' : false

                                },
                                {
                                  label: '*State',
                                  name: 'state',
                                  type: 'select',
                                  value: states,
                                  onChange: e => this.setState({state: e.target.value})
                                },
                                {
                                  label: '*Please identify as one:',
                                  name: 'type',
                                  type: 'radio',
                                  value: this.state.type,
                                  options: [{label: 'Company/Startup', value: 'startup'}, {label: 'Developer', value: 'developer'}],
                                },
                                {
                                  label: (this.state.type === 'developer') ? 'LinkedIn Profile' : 'LinkedIn Company Profile',
                                  name: 'linkedin',
                                  type: 'text',
                                  placeholder: (this.state.type === 'developer') ? 'https://www.linkedin.com/in/<your profile id>' : 'https://www.linkedin.com/company/<your company name>',
                                  value: this.state.linkedin,
                                  hint: (this.state.type === 'developer') ? `Please enter your linkedin profile (https://www.linkedin.com/in/<your profile id>)` : 'Please enter your Companies linkedin profile (https://www.linkedin.com/company/<your company name>)',
                                  onChange: e => this.setState({linkedin: e.target.value}),
                                  onBlur: this.isLinkedInDirty || this.state.linkedin.length >= 30 ? () => false : () => {
                                    this.setState({errors: { ...this.state.errors, linkedin: isUrlValid(this.state.linkedin, this.state.type, this.state.errors.linkedin) }})
                                    this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                  this.isLinkedInDirty = true;
                                                                },
                                  onKeyUp: this.isLinkedInDirty || this.state.linkedin.length >= 30 ? debounce(() => {
                                    this.setState({errors: { ...this.state.errors, linkedin: isUrlValid(this.state.linkedin, this.state.type, this.state.errors.linkedin) }})
                                    this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                  }, 300) : () => false,
                                  isInvalid: this.state.errors.linkedin,
                                  validationMessage: this.state.errors.linkedin ? 'LinkedIn profile must be at filled in correctly' : false

                                },
                                {
                                  label: 'Email',
                                  name: 'email',
                                  type: 'email',
                                  value: this.state.email,
                                  onChange: e => this.setState({email: e.target.value}),
                                  onBlur: this.isEmailDirty  ? () => false : debounce(() => { 
                                                                                this.setState({errors: { ...this.state.errors, email: validateEmail(this.state.email) }})
                                                                                this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                                this.isEmailDirty = true;
                                                                              },100),
                                  onKeyUp: this.isEmailDirty ? debounce(() => {
                                                                  this.setState({errors: { ...this.state.errors, email: validateEmail(this.state.email) }})
                                                                  this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                },100) : () => false,
                                  isInvalid: this.state.errors.email,
                                  validationMessage: this.state.errors.email ? 'Please provide valid email' : false
                                },
                                {
                                  label: 'Password',
                                  name: 'password',
                                  type: 'password',
                                  value: this.state.password,
                                  onChange: e => this.setState({password: e.target.value}),
                                  onBlur: this.isPwDirty  ? () => false : debounce(() => {
                                                                            this.setState({errors: { ...this.state.errors, password: validatePassword(this.state.password) }})
                                                                            this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                                            this.isPwDirty = true;
                                                                          },100),
                                  onKeyUp: (this.isPwDirty || this.state.password.length > 8) ? debounce(() => {
                                                              this.setState({ errors: { ...this.state.errors, password: validatePassword(this.state.password) }})
                                                              this.setState({ isInvalid: isFormInvalid({city: this.state.city, email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, linkedin: this.state.linkedin, password: this.state.password, errors: this.state.errors})})
                                                            },1000) : () => false,
                                  isInvalid: this.state.errors.password,
                                  validationMessage: this.state.errors.password ? 'Please provide valid password' : false,
                                  hint: 'Password must be a minimum of eight characters and at least one digit and capital letter'
                                }];
    return REGISTRATION_INPUTS;
  }

  render() {
    if (this.state.redirect) // if already logged in..
      return <Redirect push to={{pathname: 'profile/' + this.state.userId }} />;
    return (
      <div>
        <Auth/>

        <Pane
          maxWidth={725}
          margin="auto"
          marginTop={(window.innerWidth > 750) ? 25 : 10}
          marginBottom={15}
          align="center"
          padding={25}
          paddingBottom={0}>
          <Heading is="h1"
            size={(window.innerWidth > 750) ? 900 : 700}
            marginBottom={15}>
            A community of <strong>vetted Software Engineers</strong> and <strong>Companies/Startups</strong> 
          </Heading>
          <Text
            size={(window.innerWidth > 750) ? 500 : 400}>
            Sign up for <strong>free</strong> today and expand your career and/or Company.
          </Text>       
        </Pane>
        
        <Pane 
            className="flex-grid"
            alignItems="top"
            justifyContent="center"
            padding={5}>
          <Pane
            className="m-pane-out"
            alignItems="top"
            justifyContent="center"
            flexBasis={870}
            marginBottom={25}
            padding={20}>
            <Heading 
              size={600}
              marginBottom={15}>
              Sign Up
            </Heading>
            <Pane
                className="pane-el m-pane-el"
                elevation={1}
                padding={50}
                width="100%">
                {
                  this.registrationInputs().map((value, index) => {
                      if (value.type === 'select') {
                        const selectHTML = <SelectField
                                              label={value.label}
                                              onChange={value.onChange}
                                              key={index}
                                              width="50%"
                                              marginBottom={25}>
                                                {Object.keys(value.value).map((val, idx) => {
                                                  return <option value={val} key={idx}>{value.value[val]}</option>;
                                                })}
                                            </SelectField>;
                        return selectHTML;
                      }
                      if (value.type === 'radio') {
                        return <RadioGroup
                                    key={index}
                                    marginBottom={20}
                                    size={(window.innerWidth > 750) ? 18 : 16}
                                    label={value.label}
                                    value={value.value}
                                    options={value.options}
                                    onChange={e => this.setState({type: e, errors: {...this.state.errors, linkedin: isUrlValid(this.state.linkedin, e, this.state.errors.linkedin)}})}>
                                  </RadioGroup>;
                      }
                      return  <TextInputField  key={index} {...value} inputHeight={(window.innerWidth > 750) ? 40 : 35} required/>;
                  })
                }
                <Pane>
                  <Button
                    appearance="primary"
                    disabled={this.state.isInvalid}
                    isLoading={this.state.isLoading}
                    onClick={this.registerInit}>
                    Sign Up
                  </Button>
                </Pane>
              </Pane>
          </Pane>
      </Pane>
      </div>
    );
  }
}

export default Registration;
