import React, { Component } from 'react';
import { restCall } from '../services/rest';
import { Pane, Heading, Paragraph, toaster, Link } from 'evergreen-ui';

class Verify extends Component {
  constructor(props) {
    super(props);

    this.verifytoken = window.location.pathname.split('/')[2];
    this.userid = window.location.pathname.split('/')[3];
    this.internal = window.location.pathname.split('/')[4];
  }

  componentDidMount() {
    restCall('/verify/', 'POST', null, {id: this.userid, verifytoken: this.verifytoken, internal: this.internal ? true : false})
      .then(res => res.json())
      .then(data => {
        if (!data.result)
          return toaster.warning(data.msg);
        toaster.success(data.msg, {description: data.description, duration: 25});
      })
      .catch(err => {
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  render() {

    return (

      <Pane 
        className="flex-grid"
        alignItems="top"
        justifyContent="center"
        padding={25}>

        <Pane
          className="flex-grid"
          flexBasis={850}
          alignItems="top"
          justifyContent="center"
          marginBottom={25}
          padding={10}
        >
          <Pane width="100%">
            <Heading 
              size={700}
              marginBottom={15}
              >
              Verify
            </Heading>
            <Pane
                elevation={1}
                width="100%"
                padding={50}              
              >
            <Paragraph>
              This is the token that your are verifying: <strong>{window.location.pathname.split('/verify/')[1].substring(0, 25)}...</strong>
            </Paragraph>
            <Paragraph>
              You should be recieving a toast notification confirming that in the near future.
            </Paragraph>
            {!this.internal && <Link href="/">Login</Link>}
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

export default Verify;