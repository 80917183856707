import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { restCall } from '../services/rest';
import { signOut, loggedIn } from '../services/utils';
import { ORIGIN } from '../services/rest';
import { toaster } from 'evergreen-ui';

export const Context = React.createContext();

class Provider extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      firstname: '',
      lastname: '',
      id: '',
      email: '',
      city: '',
      state: '',
      type: '',
      companyname: '',
      employer: '',
      linkedin: '',
      years: '',
      languageLabels: [],
      errors: { firstname: false, lastname: false, city: false, email: false, linkedin: false },
      errorMsg: {email: 'Please provide valid email', text: 'Must be at least 2 characters'},
      isActive: true,
      isInvalid: false,
      isLoading: false,
      isRestDataAvail: false,
      disabled: true,
      redirect: false,
      setContext: (state) => this.setState({state: {...state}}),
      setValue: (state, val) => { 
        this.setState({[state]: val})
      },
      setValues: (vals, cb) => this.setState(vals, () => {
        if (cb)
          return cb()
      }),
      setErrors: (state, val) => this.setState({errors: { ...this.state.errors, [state]: val}}),
      onEdit: (param) => this.setState({disabled: !param}),
      setUser: () => this.setUser(),
      signOut: delay => signOut(delay)
    };
  }

  // Initiates Event Message, notifications of message. Initiates below render
  initEventMessage() {
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    this.eventMessage =  new EventSource(ORIGIN + '/event-message/' + tokenId.id, {id: tokenId.id});
    this.eventMessage.onmessage = e => {
      if (e.data) {
        this.getMappedMessages();
      }
    }
  }

  // Initiates below render..
  getUser() {
    // Get user object...
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    restCall('/user/', 'POST', tokenId.reg_auth, {id: tokenId.id, rest: 'get'})
      .then(res => res.json())
      .then(data => {
        if (!data.result) {
          signOut(2000); // Delay user before redirect
          return toaster.warning(data.msg);
        }
        const user = data.result;
        this.setState(prevState => ({isRestDataAvail: true, ...user}));
        this.getMappedMessages();
      })
      .catch(err => {
        this.setState({isLoading: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  // Sets user through context
  setUser() {
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    this.setState({isLoading: true});
    let setobj = {payload: {}, id: tokenId.id, rest: 'set'};
    Object.keys(this.state).forEach(val => {
      if (typeof this.state[val] === 'string' || val === 'languageLabels' || val === 'isActive') setobj.payload[val] = this.state[val];
    });
    restCall('/user/', 'POST', tokenId.reg_auth, setobj)
      .then(res => res.json())
      .then(data => {
        this.setState({isLoading: false, disabled: true});
        if (!data.result)
          return toaster.notify(data.msg);
        toaster.success(data.msg, {duration: 15});
      })
      .catch(err => {
        this.setState({isLoading: false, disabled: false});
        toaster.notify('Sorry, something went wrong, please try back later');
        console.error('Err ', err);
      });
  }

  // Get mapped messages, determine if messages are read, is called from event-message as well
  getMappedMessages() {
    const tokenId = JSON.parse(localStorage.getItem('start_dev'));
    restCall('/messages-mapped/', 'POST', tokenId.reg_auth, {id: this.state.messagesMappedId})
        .then(res => res.json())
        .then(data => {
          if (!data.result) {
            signOut(2000); // Delay user before redirect
            return toaster.warning(data.msg);
          }
          let numUnread = 0;
          data.data.messages.forEach(val => {
            if (!val.isRead)
              ++numUnread;
          })
          this.setState({messagesLength: data.data.messages.length, messagesUnread: numUnread});
        })
        .catch(err => {
          this.setState({isLoading: false});
          toaster.notify('Sorry, something went wrong, please try back later');
          console.error('Err ', err);
        });
  }

  render() {
    // Hacky below, should get rid of. Find out how restcalls with Context.
    if (loggedIn().isLoggedIn &&
        !this.state.isRestDataAvail && 
        !window.location.pathname.match('/verify/') && // In case user may have expired token..
        !window.location.pathname.match('/reset-password/')) { // In case user may have expired token..
      this.getUser();
      this.initEventMessage();
    }

    if (this.state.redirect)
      return <Redirect push to={{pathname: '/profile/' + this.state.id }} />;

    return (
      <div>
        <Context.Provider value={this.state}>
          { this.props.children }
        </Context.Provider>
      </div>
    )
  }  
}

export default Provider;
